
.MapBackground-logo {
  pointer-events: none;
}

.MapBackground-header {
background-color: #ffffff;
float: left;
clear: both;
}
h1 {
  text-align: center;
}
svg.MapBackground {
  float: left;
  margin: 20px;
}

div.MapNodeText {
  clear: both;
  margin: 20px;
}

div.MapNodeList {
  float: left;
  margin: 20px;
}

div.MapNodeDetails {
  float: left;
  margin: 20px;
}

.SubDivider {
  stroke: lightgrey;
  stroke-width: 2;
  stroke-dasharray: 10;
}

.EdgeDivider {
  stroke: grey;
  stroke-width: 4;
}


