
.mapNodeListItem {
  margin: 0 0 1em 0;
  list-style-type: none;
  padding: 0;
}

.mapNodeListItem li {
  cursor: pointer;
  position: relative;
  left: 0;
  background-color: #EEE;
  margin: .4em;
  padding: .2em 0;
  height: 1.4em;
  border-radius: 4px;
}


.nodeType {
  display: inline-block;
  font-size: small;
  color: grey;
  padding: 0.6em 0.5em 0 0.5em;
  background-color: lightgrey;
  line-height: 0.6em;
  position: relative;
  height: 1.2em;
  margin-left: .8em;
  border-radius: 4px 0 0 4px;
}

.nodeName {
  display: inline-block;
  font-size: small;
  color: grey;
  padding: 0.6em 0.5em 0 0.5em;
  background-color: lightgrey;
  line-height: 0.6em;
  position: relative;
  height: 1.2em;
  margin-right: .8em;
  border-radius: 0 4px 4px 0;
}

.selected {
  background-color: grey;
  color: white;
}
