body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.MapBackground-logo {
  pointer-events: none;
}

.MapBackground-header {
  background-color: #282c34;
}

circle {
  stroke-width: 1;
  stroke: blue;
  fill: white
}

rect {
  stroke-width: 1;
  fill: white;
  stroke: blue;
}

.userNeed {
  stroke: green;
}

.subMap {
  stroke: red;
}

.selected {
  stroke-width: 2;
}

text {
  text-anchor: middle;
  stroke: blue;
  stroke-width: 0.5;
}

text.selected {
  stroke-width: 1;
}



.connector {
  stroke: grey;
}


.mapNodeListItem {
  margin: 0 0 1em 0;
  list-style-type: none;
  padding: 0;
}

.mapNodeListItem li {
  cursor: pointer;
  position: relative;
  left: 0;
  background-color: #EEE;
  margin: .4em;
  padding: .2em 0;
  height: 1.4em;
  border-radius: 4px;
}


.nodeType {
  display: inline-block;
  font-size: small;
  color: grey;
  padding: 0.6em 0.5em 0 0.5em;
  background-color: lightgrey;
  line-height: 0.6em;
  position: relative;
  height: 1.2em;
  margin-left: .8em;
  border-radius: 4px 0 0 4px;
}

.nodeName {
  display: inline-block;
  font-size: small;
  color: grey;
  padding: 0.6em 0.5em 0 0.5em;
  background-color: lightgrey;
  line-height: 0.6em;
  position: relative;
  height: 1.2em;
  margin-right: .8em;
  border-radius: 0 4px 4px 0;
}

.selected {
  background-color: grey;
  color: white;
}


.MapBackground-logo {
  pointer-events: none;
}

.MapBackground-header {
background-color: #ffffff;
float: left;
clear: both;
}
h1 {
  text-align: center;
}
svg.MapBackground {
  float: left;
  margin: 20px;
}

div.MapNodeText {
  clear: both;
  margin: 20px;
}

div.MapNodeList {
  float: left;
  margin: 20px;
}

div.MapNodeDetails {
  float: left;
  margin: 20px;
}

.SubDivider {
  stroke: lightgrey;
  stroke-width: 2;
  stroke-dasharray: 10;
}

.EdgeDivider {
  stroke: grey;
  stroke-width: 4;
}



