
.MapBackground-logo {
  pointer-events: none;
}

.MapBackground-header {
  background-color: #282c34;
}

circle {
  stroke-width: 1;
  stroke: blue;
  fill: white
}

rect {
  stroke-width: 1;
  fill: white;
  stroke: blue;
}

.userNeed {
  stroke: green;
}

.subMap {
  stroke: red;
}

.selected {
  stroke-width: 2;
}

text {
  text-anchor: middle;
  stroke: blue;
  stroke-width: 0.5;
}

text.selected {
  stroke-width: 1;
}

